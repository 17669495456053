import Icon from "./icons";

export default class LogoSection {

  constructor(el) {

    this.$body = document.body;
    this.$section = el;

    // this.preloadImages();

    this.tl = gsap.timeline();
    this.tl.add(this.getBackgroundTimeline(), "a");
    this.tl.add(this.getLogoTimeline(), "a");
    this.tl.add(this.getCarpetTimeline(), "a");
    this.tl.pause();

    this.initCarpetSlider();


    // let that = this;
    // document.addEventListener("DOMContentLoaded", function () {
    //   var images = document.querySelectorAll(".carpet-slider__slide img");
    //   var totalImages = images.length;
    //   var loadedImages = 0;

    //   function imageLoaded(i) {
    //     loadedImages++;
    //     // gsap.fromTo(i, {
    //     //   opacity: 1,
    //     //   z: 0,
    //     // }, {
    //     //   duration: 1.2,
    //     //   opacity: 0.5,
    //     //   z: 16,
    //     // })
    //     // console.log(i.src, "loaded");
    //     i.classList.add("loaded");
    //     if (loadedImages === totalImages) {
    //       // that.tl.add(that.getCarpetTimeline());
    //     }
    //   }

    //   for (var i = 0; i < totalImages; i++) {
    //     images[i].addEventListener("load", imageLoaded(images[i]));
    //   }
    // });


    // Make sure we start at the top when animating the logo intro
    if (window.scrollY > 0) {

      // Otherwise scroll to top first
      gsap.to(window, 0.6, {
        scrollTo: {
          y: 0
        },
        onComplete: () => {
          this.initTimeline();
        }
      });
    }

    // Otherwise just start the logo animation
    else {
      this.initTimeline();
    }

  }


  preloadImages() {
    let $counter = this.$section.querySelector(".logo-section__counter");
    document.addEventListener("DOMContentLoaded", function () {
      let images = document.querySelectorAll(".carpet-slider__slide img");
      let totalImages = images.length;
      let loadedImages = 0;

      function imageLoaded(i) {
        loadedImages++;
        // gsap.fromTo(i, {
        //   opacity: 1,
        //   z: 0,
        // }, {
        //   duration: 1.2,
        //   opacity: 0.5,
        //   z: 16,
        // })
        // console.log(i.src, "loaded");
        // Calculate the percentage of loaded images
        // let percentage = Math.round((loadedImages / totalImages) * 100);
        // write the number of loaded images to the counter
        // $counter.innerHTML = loadedImages;
        i.classList.add("loaded");
        if (loadedImages === totalImages) {
          // that.tl.add(that.getCarpetTimeline());
        }
      }

      for (let i = 0; i < totalImages; i++) {
        images[i].addEventListener("load", imageLoaded(images[i]));
      }
    });
  }


  initCarpetSlider() {

    // Check if there is a slider
    const $slider = this.$section.querySelector('.carpet-slider');
    if (!$slider) return;

    const $sliders = $slider.querySelectorAll('.carpet-slider__slider');
    $sliders.forEach(slider => {

      const $group = slider.querySelector('.carpet-slider__group');

      // Copy slider to the end of the wrapper
      slider.appendChild($group.cloneNode(true));

      const $groups = slider.querySelectorAll('.carpet-slider__group');
      $groups.forEach(slide => {
        gsap.to(slide, {
          duration: () => calculateDynamicDuration(window.innerWidth, 10), // Use the dynamic duration function
          yPercent: -100,
          ease: "none",
          repeat: -1
        });
      });

    });


    function calculateDynamicDuration(viewportWidth, speedFactor = 1) {
      // Define a base width and a base duration for the calculation
      const baseWidth = 1440; // Base width (e.g., the width of a large desktop screen)
      const baseDuration = 80; // Base duration at the base width

      // Calculate the duration based on the current viewport width
      // The duration increases as the viewport width decreases
      let duration = baseDuration * (viewportWidth / baseWidth);

      // Adjust the duration with the speed factor
      duration *= speedFactor;

      // Ensure the duration does not go above a maximum threshold
      const maxDuration = 80;
      return Math.min(duration, maxDuration);
    }

  }


  // Animate logo section
  // 1. Scroll to logo section
  // 2. Animate logo
  // 3. Scroll to next section

  initTimeline() {

    const tl = gsap.timeline({
      scrollTrigger: {
        // markers: true,
        trigger: this.$section,
        start: "top 1px",
        end: "bottom center",
        // snap: true,
        onEnter: () => {

          // Block scrolling for logo animation
          // this.$body.style.overflow = "hidden";

          // Add release scrolling once the logo animation is done
          this.tl.eventCallback("onComplete", () => {

            // Release scrolling
            // this.$body.style.overflow = "auto";

            this.handleScrollDownButton();

            // gsap.from(this.scrollDownButton, {
            //   duration: 0.6,
            //   opacity: 0,
            //   y: 16,
            //   ease: "power4.out"
            // });

            // // Automatically scroll to next section when the logo is done
            // gsap.to(window, 1.2, {
            //   scrollTo: {
            //     y: window.innerHeight
            //   },

            //   // Once the scroll is done...
            //   onComplete: () => {

            //     // ... hide the logo section completely
            //     this.$section.style.display = "none";

            //     // Reset scroll position
            //     gsap.set(window, {
            //       scrollTo: {
            //         y: 0
            //       }
            //     })

            //     // Update ScrollTrigger
            //     ScrollTrigger.refresh();
            //     ScrollTrigger.update();
            //   }
            // });

          });

          // Play logo animation
          this.tl.play();
        }
      }
    });

    tl.set(this.$section, {
      autoAlpha: 1
    });

    return tl;

  }




  // Logo animation timeline
  getLogoTimeline() {

    const
      $logo = this.$section.querySelector('#logo'),
      $centerLine = $logo.querySelectorAll('.line'),
      $characters = $logo.querySelectorAll('.char'),
      $leftCahrs = $logo.querySelectorAll('.left'),
      $rightCahrs = $logo.querySelectorAll('.right'),
      logoTL = gsap.timeline();

    logoTL.set($centerLine, {
      transformOrigin: "center bottom",
      scaleY: 0,
      opacity: 0,
    })

    logoTL.to($logo, {
      duration: 1.6,
      y: "-=16",
    }, "a");

    logoTL.to($centerLine, {
      duration: 0.6,
      scaleY: 1,
      opacity: 1,
    }, "a");

    logoTL.from($characters, {
      duration: 0.6,
      scale: 0.9,
      y: 12,
      opacity: 0,
      stagger: {
        from: "center",
        amount: 0.3
      }
    }, "a");

    logoTL.set($centerLine, {
      transformOrigin: "center center",
    }, "b");

    logoTL.to($centerLine, {
      duration: 0.2,
      rotateZ: 6,
      ease: "back.out(4)"
    }, "c");

    logoTL.to($leftCahrs, {
      duration: 0.2,
      x: -2,
      ease: "back.out(4)"
    }, "c");

    logoTL.to($rightCahrs, {
      duration: 0.2,
      x: 2,
      ease: "back.out(4)"
    }, "c");

    logoTL.to($logo, {
      duration: 1,
    });

    return logoTL;

  }


  // Logo animation timeline
  getBackgroundTimeline() {

    const
      $aurora = this.$section.querySelector('.logo-section__aurora'),
      tl = gsap.timeline();

    tl.from($aurora, {
      duration: 0.9,
      opacity: 0
    }, "a");

    return tl;

  }


  // Get carpet timeline
  getCarpetTimeline() {

    const
      $carpet = this.$section.querySelector('.carpet-slider__container'),
      $slides = this.$section.querySelectorAll('.carpet-slider__slide'),
      tl = gsap.timeline();

    // tl.fromTo($carpet, {
    //   y: "+=25%",
    //   z: "-=25%",
    //   rotateX: 0,
    //   opacity: 0,
    // }, {
    //   duration: 3,
    //   y: "0",
    //   z: "0",
    //   rotateX: 45,
    //   opacity: 1,
    //   ease: "power4.out",
    // }, "a");

    // tl.from($slides, {
    //   duration: 3,
    //   opacity: 0,
    //   // y: "+=50",
    //   ease: "power4.out",
    //   force3D: true,
    //   stagger: {
    //     from: "random",
    //     amount: 3
    //   }
    // }, "a");

    return tl;

  }


  // On scroll down button click
  handleScrollDownButton() {

    const $scrollDownButton = this.$section.querySelector('.logo-section__button');

    // show the scroll down button
    gsap.to($scrollDownButton, {
      duration: 1.2,
      autoAlpha: 1,
      onStart: () => {
        new Icon($scrollDownButton.querySelector('svg'));
      }
    });


    $scrollDownButton.addEventListener('click', () => {

      // Get top position of the following section
      const $nextSection = this.$section.nextElementSibling;
      $nextSection.scrollIntoView({
        behavior: 'smooth'
      });

    });
  }

}
